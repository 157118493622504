/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Login',
  'user.register': 'Register',
  'user.forgot-password': 'Forgot Password',
  'user.email': 'E-mail',
  'user.password': 'Password',
  'user.forgot-password-question': 'Forget password?',
  'user.fullname': 'Full Name',
  'user.login-button': 'LOGIN',
  'user.register-button': 'REGISTER',
  'user.create-account': 'CREATE ACCOUNT',
  'user.reset-password-button': 'RESET',
  'user.buy': 'BUY',
  'user.username': 'Username',
  'register.company-name': 'Company Name',

  /* 03.Menu */
  'menu.app': 'Home',
  'menu.dashboards': 'Dashboards',
  'menu.dashboard': 'Dashboard',
  'menu.employees': 'Employees',
  'menu.trainings': 'Activities',
  'menu.assignments': 'Assignments',
  'menu.list': 'Associated Users',
  'menu.editprofile': 'Edit Profile',
  'menu.exposures': 'Exposures',
  'menu.observations': 'Performance Reviews',
  'menu.invites': 'Send Invites',
  'menu.feedback360': 'Bulletins',
  'menu.engagements': 'Engagements',
  'menu.awards': 'Awards',
  'menu.traininglogs': 'Activities',
  'menu.addpersonaltraining': 'Add Personal Training Record',
  'menu.evaluations': 'Evaluations',
  'menu.trialservice': 'Trial Service',
  'menu.addrecord': 'Add Training Record',
  'menu.addusercertification': 'Add new Certification',
  'menu.taskbooks': 'TaskBooks',
  'menu.skillsheets': 'Competencies',
  'menu.dailylog': 'Daily Log',
  'menu.observationreports': 'Observ. Reports',
  'menu.taskbookusercarddetails': 'Books',
  'menu.profile': 'User TaskBooks',
  'menu.systemlog': 'System Log',
  'menu.settings': 'Settings',
  'menu.userview': 'User Cards',
  'menu.users': 'Users',
  'menu.gogo': 'Gogo',
  'menu.start': 'Start',
  'menu.second-menu': 'Second Menu',
  'menu.second': 'Second',
  'menu.addexposure': 'Add Exposure',
  'menu.ui': 'UI',
  'menu.charts': 'Charts',
  'menu.chat': 'Chat',
  'menu.survey': 'Survey',
  'menu.todo': 'Todo',
  'menu.search': 'Search',
  'menu.docs': 'Docs',
  'menu.blank-page': 'Blank Page',
  'menu.add': 'Add',
  'menu.recent': 'Recent',
  'menu.view': 'View Taskbooks',
  'menu.engagementlist': 'User Engagements',
  'menu.engagementuserlist': 'Engagements',
  'menu.engagementdetails': 'Details',
  'menu.deactivatedlist': 'Inactive Users',
  'menu.addbook': 'New TaskBook',
  'menu.edit': 'Edit User',
  'menu.addengagement': 'New Engagement',
  'menu.me': 'My Profile',
  'menu.reports': 'Reports',
  'menu.trainingdetails': 'Training Details',
  'menu.exposuredetails': 'Exposure Details',
  'menu.eventdetails': 'Event Details',

  /* 04.Error Page */
  'pages.error-title': 'Ooops... looks like an error occurred!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',
  'pages.profile': 'Profile',
  'pages.logs': 'Logs',
  'pages.friends': 'Friends',
  'pages.orderby': 'Order By: ',
  'pages.add-new': 'ADD NEW',

  'training-details': 'Training Details:',

  'taglines.traininglogs':
    'Easily track, maintain and query Training Records for your Organization. Training Records entered into the platform will follow an employee for their entire career and are easily viewable from within their personal profile.'
};
