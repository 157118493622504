import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, FormFeedback, CardTitle, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Colxx } from '../../components/common/CustomBootstrap';
import ReactstrapFormikInput from '../../components/forms/ReactstrapFormikInput';
import { errorMessage, successMessage } from '../../helpers/Utils';
import UserLayout from '../../layout/UserLayout';
import { registerUser } from '../../redux/auth/actions';
import Features from './features';
import './login-styles.css';

const SignupSchema = Yup.object().shape({
  company_name: Yup.string()
    .min(2, 'Please enter 2 or more characters!')
    .required('Please enter the name of your company!'),
  first_name: Yup.string()
    .min(2, 'Please enter 2 or more characters!')
    .max(100, 'Too Long!')
    .required('Please enter your first name!'),
  last_name: Yup.string()
    .min(2, 'Please enter 2 or more characters!')
    .max(100, 'Too Long!')
    .required('Please enter your last name!'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .required('Please choose a password!'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Please enter your email address!')
});

const UserSignupSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Please enter 2 or more characters!')
    .max(100, 'Too Long!')
    .required('Please enter your first name!'),
  last_name: Yup.string()
    .min(2, 'Please enter 2 or more characters!')
    .max(100, 'Too Long!')
    .required('Please enter your last name!'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .required('Please choose a password!'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Please enter your email address!')
});

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompany: !props.inviteId,
      industry: 'Fire Dept'
    };
  }

  componentDidMount() {
    if (this.props.inviteId) {
      this.props.loadInvite();
    }
  }

  goToLogin = () => {
    this.props.history.push('/user/login');
  };

  toggleCompany = () => {
    this.setState(prevState => ({
      isCompany: !prevState.isCompany
    }));
  };

  getEmail = values => {
    return !this.props.loading && this.props.invite ? this.props.invite.email : values.email;
  };

  updateIndustry = e => {
    this.setState({
      industry: e.label
    });
  };

  onUserRegister = (values, props) => {
    const { registerUser, onStateChange } = this.props;

    if (this.emailValidator(values.email)) {
      registerUser(
        {
          ...values,
          email: this.getEmail(values),
          industry: this.state.industry,
          inviteId: this.props.inviteId
        },
        onStateChange
      )
        .then(() => {
          successMessage('Account created! Please log in.');
          this.goToLogin();
        })
        .catch(err => {
          const response = err && err.response ? err.response.data : {};
          const prop =
            response && response.error && response.error.match(/email/gi) ? 'email' : 'password';

          props.setErrors({
            [prop]: response.error
          });
        })
        .finally(() => {
          props.setSubmitting(false);
        });
    } else {
      errorMessage('Free email accounts (gmail, yahoo, etc) are not allowed.');
      props.setSubmitting(false);
    }
  };

  emailValidator(value) {
    const isValid = !/\@(hotmail|yahoo|gmail|aol|proton|outlook|)\.com/.test(value);
    return isValid;
  }

  render() {
    const { isCompany } = this.state;
    const { invite, loading, inviteId } = this.props;
    return (
      <div>
        <UserLayout>
          <Row
            style={{
              marginRight: 0,
              marginLeft: 0,
              justifyContent: 'center',
              background: '#0b1b34'
            }}
          >
            <Colxx lg='5' md='5' sm='12' style={{ background: '#0b1b34' }}>
              <div
                style={{
                  height: '100vh',
                  padding: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <div style={{ padding: 20, border: 'solid 3px #1c3b6c' }}>
                  <CardTitle
                    className='mb-4'
                    style={{ fontWeight: 900, textAlign: 'center', color: '#fff' }}
                  >
                    Create LastRecord Account
                  </CardTitle>
                  <Formik
                    initialValues={{
                      company_name: '',
                      email: this.getEmail({ email: '' }),
                      first_name: '',
                      last_name: '',
                      password: ''
                    }}
                    validationSchema={isCompany ? SignupSchema : UserSignupSchema}
                    onSubmit={this.onUserRegister}
                  >
                    {props => {
                      const { setFieldValue, errors, values, touched } = props;

                      if (!loading && invite && !values.email) {
                        setFieldValue('email', invite.email);
                      }

                      const industries = [
                        { value: 'fire', label: 'Fire Dept' },
                        { value: 'ems', label: 'EMS' },
                        { value: 'le', label: 'Law Enforcement' },
                        { value: 'manuf', label: 'Manufacturing' },
                        { value: 'other', label: 'Other' }
                      ];

                      return (
                        <Form>
                          {isCompany && (
                            <>
                              <span style={{ color: '#ffee' }}>Organization Name</span>
                              <Field
                                component={ReactstrapFormikInput}
                                type='text'
                                name='company_name'
                              />
                            </>
                          )}
                          {!loading && invite && (
                            <FormGroup className='mb-3 mt-4'>
                              <Label className='text-small text-semi-muted'>Organization</Label>
                              <p className='text-medium' style={{ color: '#ffee' }}>
                                {invite.Company.name}
                              </p>
                            </FormGroup>
                          )}
                          {!loading && invite && (
                            <FormGroup className='mb-3'>
                              <Label
                                className='text-small text-semi-muted'
                                style={{ color: '#ffee', marginTop: 10 }}
                              >
                                Email
                              </Label>
                              <p style={{ color: '#ffee', marginTop: 10 }}>{invite.email}</p>
                              <Field type='hidden' name='email' value={invite.email} />
                              {touched.email && errors.email && (
                                <FormFeedback className='d-block'>{errors.email}</FormFeedback>
                              )}
                            </FormGroup>
                          )}
                          {loading && inviteId && (
                            <div style={{ lineHeight: 2 }}>
                              <Skeleton count={2} height={45} className='mb-4' />
                            </div>
                          )}
                          <Row>
                            <Colxx lg='6' sm='6' className='pr-0'>
                              <span style={{ color: '#ffee', marginTop: 10 }}>First Name</span>
                              <Field
                                component={ReactstrapFormikInput}
                                type='text'
                                name='first_name'
                              />
                            </Colxx>
                            <Colxx lg='6' sm='6' className='pl-1'>
                              <span style={{ color: '#ffee', marginTop: 10 }}>Last Name</span>
                              <Field
                                component={ReactstrapFormikInput}
                                type='text'
                                name='last_name'
                              />
                            </Colxx>
                          </Row>
                          {!invite && (
                            <>
                              <span style={{ color: '#ffee', marginTop: 10 }}>Email</span>
                              <Field component={ReactstrapFormikInput} type='email' name='email' />
                            </>
                          )}
                          <span style={{ color: '#ffee', marginTop: 10 }}>Password</span>
                          <Field
                            component={ReactstrapFormikInput}
                            type='password'
                            name='password'
                          />
                          {!invite && (
                            <>
                              <span style={{ color: '#ffee', marginTop: 10 }}>Industry</span>
                              <Select
                                className='react-select'
                                classNamePrefix='react-select'
                                name='industry'
                                value={this.state.industry}
                                options={industries}
                                onChange={this.updateIndustry}
                                placeholder={this.state.industry}
                              />
                            </>
                          )}
                          <div className='mt-4'>
                            <Button
                              disabled={props.isSubmitting}
                              type='submit'
                              className='mr-4 mt-2 action-button'
                              style={{
                                position: 'relative',
                                borderRadius: 5,
                                borderColor: '#393939'
                              }}
                              size='lg'
                            >
                              Create Account
                            </Button>
                            {!invite && (
                              <Button
                                type='reset'
                                onClick={this.goToLogin}
                                color='light'
                                className='mt-2'
                                style={{
                                  borderRadius: 5
                                }}
                                size='lg'
                              >
                                Sign In instead
                              </Button>
                            )}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
              <div className='login-footer'>
                By logging in, you agree to our{' '}
                <a href='https://www.lastrecord.com/terms'>terms of service</a> and{' '}
                <a href='https://www.lastrecord.com/privacy'>privacy policy</a>.
              </div>
            </Colxx>
          </Row>
        </UserLayout>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.auth.loading || state.loading.models.invites,
  user: state.auth.user,
  invite: ownProps.inviteId ? state.invites.find(i => i.id == ownProps.inviteId) : null
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    registerUser: (values, onStateChange) => dispatch(registerUser(values, onStateChange)),
    loadInvite: () => dispatch.invites.get({ id: ownProps.inviteId })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
