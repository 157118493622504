import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Alert, Button, CardTitle, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import ReactstrapFormikInput from '../../components/forms/ReactstrapFormikInput';
import useToken from '../../helpers/useToken';
import UserLayout from '../../layout/UserLayout';
import { loginUser } from '../../redux/auth/actions';
import './login-styles.css';

const Login = ({ onStateChange, loginUser, loading, error, history }) => {
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const { state } = useLocation();
  const { setToken } = useToken();

  const goToSignUp = () => {
    history.push('/user/register');
  };

  const goToConfirm = () => {
    history.push('/user/confirm');
  };

  const goToForgotPassword = () => {
    history.push('/user/forgot-password');
  };

  function attemptLogin(values) {
    loginUser(values, onStateChange)
      .then(token => {
        setToken(token);
      })
      .then(() => setRedirectToReferrer(true))
      .catch(err => {
        if (err.code === 'UserNotConfirmedException') {
          goToConfirm(values);
        }
      });
  }

  if (redirectToReferrer === true) {
    return <Redirect to={state?.from || '/app'} />;
  }

  return (
    <div>
      <UserLayout>
        <Row
          style={{
            marginRight: 0,
            marginLeft: 0,
            justifyContent: 'center',
            background: '#f0f0f0'
          }}
        >
          <Colxx lg='3' md='3' sm='12' style={{ background: '#f0f0f0' }}>
            <div
              style={{
                height: '100vh',
                justifyContent: 'center',
                padding: 40,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div style={{ padding: 20, border: 'solid 3px #d9d9d9' }}>
                <CardTitle
                  className='mb-4'
                  style={{ fontWeight: 900, textAlign: 'center', color: '#212121' }}
                >
                  Sign in to LastRecord
                </CardTitle>
                <Formik
                  initialValues={{ email: '', password: '' }}
                  onSubmit={values => attemptLogin(values)}
                >
                  {props => (
                    <Form>
                      <span style={{ color: '#212121' }}>Email (case sensitive)</span>
                      <Field
                        component={ReactstrapFormikInput}
                        type='email'
                        name='email'
                        required
                        className='mb-4'
                      />

                      <span style={{ color: '#212121' }}>Password</span>
                      <Field
                        component={ReactstrapFormikInput}
                        type='password'
                        name='password'
                        className='mb-4'
                      />

                      {error && (
                        <Alert color='danger' style={{ color: '#fff', border: '1px solid red' }}>
                          {error.error}
                        </Alert>
                      )}
                      <div style={{ display: 'flex' }}>
                        <Button
                          className='mr-4 mt-2'
                          style={{ borderRadius: 5 }}
                          size='lg'
                          type='submit'
                          disabled={loading}
                        >
                          Sign In
                        </Button>
                      </div>
                      <div className='float-left mt-4'>
                        <a
                          href='#'
                          className='text-muted'
                          onClick={e => goToForgotPassword(props.values)}
                        >
                          Forgot password?
                        </a>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className='login-footer'>
              By logging in, you agree to our{' '}
              <a href='https://www.lastrecord.com/terms'>terms of service</a> and{' '}
              <a href='https://www.lastrecord.com/privacy'>privacy policy</a>.
            </div>
          </Colxx>
        </Row>
      </UserLayout>
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error
});

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (values, onStateChange) =>
      dispatch(loginUser(values.email, values.password, onStateChange))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
