import React from 'react';

function Features() {
  return (
    <div
      style={{
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center'
      }}
    >
      <div style={{ marginBottom: -15 }}>
        <span
          style={{
            color: '#fff',
            fontSize: '3.5em',
            fontWeight: 800,
            textShadow: '1px 1px 2px #0b1b34'
          }}
        >
          Purpose built for Public Safety:
        </span>
      </div>
      <div style={{ marginBottom: -15 }}>
        <span
          style={{
            color: '#fff',
            fontSize: '3.5em',
            fontWeight: 800,
            textShadow: '1px 1px 2px #0b1b34'
          }}
        >
          Performance Reviews
        </span>
      </div>
      <div style={{ marginBottom: -15 }}>
        <span
          style={{
            color: '#fff',
            fontSize: '3.5em',
            fontWeight: 800,
            textShadow: '1px 1px 2px #0b1b34'
          }}
        >
          TaskBooks
        </span>
      </div>
      <span
        style={{
          color: '#fff',
          fontSize: '3.5em',
          fontWeight: 800,
          textShadow: '1px 1px 2px #0b1b34'
        }}
      >
        Skills Competencies{' '}
        <span style={{ background: '#2879bf', padding: '0px 10px' }}>& more.</span>
      </span>
    </div>
  );
}

export default Features;
